import React from 'react';
import chunk from 'lodash/chunk';
import forEach from 'lodash/forEach';
import mapValues from 'lodash/mapValues';

export default class ShowCohort extends React.Component {
  constructor(props) {
    super(props);

    this.table = {};
    this.state = {
      counter: 0 // This exists so that I don't have to figure out proper state manip.
    };
    this.notState = {
      domains: window.socrata.cohort.domains,
      checks: ['module:staging_lockdown', 'flag:strict_permissions'],
      highlights: { 'flag:strict_permissions': false }
    };

    this.notState.domains.forEach((cname) => { this.table[cname] = {}; });
    this.refreshDomains();

    this.eventListeners = {
      'addDomain': (evt) => this.addDomain(evt.detail),
      'addDomains': (evt) => this.addDomains(evt.detail),
      'removeDomain': (evt) => this.removeDomain(evt.detail),
      'removeAllDomains': () => this.removeAllDomains(),
      'addCheck': (evt) => this.addCheck(evt.detail),
      'removeCheck': (evt) => this.removeCheck(evt.detail),
      'addHighlight': (evt) => this.addHighlight(evt.detail),
      'removeHighlight': (evt) => this.removeHighlight(evt.detail),
    };
  }

  componentDidMount() {
    forEach(this.eventListeners, (func, funcName) => document.addEventListener(`showCohort.${funcName}`, func));

  }

  componentWillUnmount() {
    forEach(this.eventListeners, (func, funcName) => document.removeEventListener(`showCohort.${funcName}`, func));
  }

  refreshTable() {
    this.setState({ counter: this.state.counter + 1 });
  }

  refreshDomains() {
    forEach(chunk(this.notState.domains, 10), (chunkOfDomains) => this.updateDomains(chunkOfDomains));
  }

  buildCurrentQuery() {
    const checkQuery = this.notState.checks.map((check) => `checks[]=${check}`);
    const highlightQuery = Object.entries(this.notState.highlights).
      map(([check, values]) =>
        Array(values).map((value) => `highlight[${check}][]=${value}`)
      ).join('&');
    return checkQuery.concat(highlightQuery).join('&');
  }

  async updateDomain(cname) {
    const query = this.buildCurrentQuery();

    this.table[cname] = await fetch(`/internal/domains/${cname}/readiness/matches.json?${query}`).
      then((response) => {
        if (response.ok)
          return response.json().then(this.processDomainUpdate);
        else
          return new Promise((ignored, reject) => reject('bad cname'));
      }).catch(err => { console.log(err.message); });
    this.refreshTable();
  }

  async updateDomains(listOfCnames) {
    const query = listOfCnames.
      map((cname) => `domains[]=${cname}`).
      concat(this.buildCurrentQuery()).
      join('&');

    const updates = await fetch(`/internal/domain_readiness/matches.json?${query}`).
      then((response) => {
        if (response.ok) {
          return response.json().then((json) => mapValues(json, this.processDomainUpdate));
        } else {
          console.error(response);
        }
      });
    this.table = { ...this.table, ...updates };
    this.refreshTable();
  }

  processDomainUpdate(json) {
    return mapValues(json, (result, lookup) => (lookup === 'all_match' ? result : result.value));
  }

  addDomain({ cname }) {
    this.table[cname] = {};
    this.notState.domains.push(cname);
    this.updateDomain(cname);
  }

  addDomains({ cnames }) {
    cnames.forEach((cname) => {
      this.table[cname] = {};
      this.notState.domains.push(cname);
    });
    this.updateDomains(cnames);
  }

  removeDomain({ cname }) {
    delete this.table[cname];
    this.notState.domains = this.notState.domains.filter((domain) => domain !== cname);
    this.refreshTable();
  }

  removeAllDomains() {
    this.table = {};
    this.notState.domains = [];
    this.refreshTable();
  }

  addCheck({ lookup }) {
    this.notState.checks.push(lookup);
    this.refreshDomains();
  }

  removeCheck({ lookup }) {
    this.notState.checks.push(lookup);
    this.refreshTable();
  }

  addHighlight({ lookup, value }) {
    if (this.notState.highlights.hasOwnProperty(lookup)) {
      if (this.notState.highlights[lookup] instanceof Array) {
        this.notState.highlights[lookup].push(value);
      } else {
        this.notState.highlights[lookup] = [this.notState.highlights[lookup], value];
      }
    } else {
      this.notState.highlights[lookup] = [value];
    }
    this.refreshDomains();
  }

  removeHighlight({ lookup, value }) {
    if (this.notState.highlights.hasOwnProperty(lookup)) {
      if (this.notState.highlights[lookup] instanceof Array) {
        this.notState.highlights[lookup].push(value);
        this.notState.highlights[lookup] = this.notState.highlights[lookup].
          filter((existingValue) => existingValue !== value);
      } else {
        delete this.notState.highlights[lookup];
      }
    } else {
      delete this.notState.highlights[lookup];
    }
    this.refreshTable();
  }

  renderHeader() {
    let headers = [<th key="cname" className="cname">CNAME</th>];
    if (this.notState.checks.length === 0) {
      headers.push(<th key="placeholder">.</th>);
    } else {
      this.notState.checks.forEach((check) => {
        headers.push(<th key={check}>{check}</th>);
      });
    }
    return headers;
  }

  renderBody() {
    return this.notState.domains.map((domain) => this.renderDomain(domain));
  }

  renderDomain(cname) {
    let classNames = [],
      tableCells = [
        <td key={`${cname}.cname`} className="cname" title={cname}>{cname}</td>
      ];

    if (this.table[cname] === undefined) {
      classNames.push('invalid');
      this.notState.checks.forEach((check) => {
        tableCells.push(
          <td key={`${cname}.${check}`} style={{ color: '#f88379' }}>.</td>
        );
      });
    } else if (this.notState.checks.length === 0) {
      tableCells.push(
        <td key={`${cname}.placeholder`}>'.'</td>
      );
    } else {
      this.notState.checks.forEach((check) => {
        const hasValue = this.table[cname].hasOwnProperty(check);
        tableCells.push(
          <td key={`${cname}.${check}`}>{hasValue ? this.table[cname][check] : '.'}</td>
        );
      });
      if (this.table[cname].all_match) { classNames.push('matches-highlight'); }
    }
    return (
      <tr
        key={`${cname}.row`}
        className={classNames.join(' ')}
      >{tableCells}
      </tr>
    );
  }

  render() {
    return (
      <div className="table-wrapper">
        <div className="table-padding">
          <table>
            <thead>
              <tr>
                {this.renderHeader()}
              </tr>
            </thead>
            <tbody>
              {this.renderBody()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
